import { Box, BoxProps } from '@mui/material';
import { keyframes } from '@mui/material/styles';
import { useMemo } from 'react';

const fade = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

interface LoaderProps extends BoxProps {
  size?: number;
  gap?: number;
  color?: string;
}

export const Loader = ({ size = 10, gap = 1, color, p = 2, ...rest }: LoaderProps) => {
  const dotStyle = useMemo(
    () => ({
      width: size,
      height: size,
      borderRadius: '50%',
      opacity: 0,
      backgroundColor: 'currentColor',
      animation: `${fade} 2s infinite ease-in-out both`,
    }),
    [size, color]
  );

  return (
    <Box display="flex" gap={gap} alignItems="center" justifyItems="center" p={p} {...rest}>
      <Box sx={{ animationDelay: '0s', ...dotStyle }}></Box>
      <Box
        sx={{
          ...dotStyle,
          animationDelay: '0.5s',
        }}
      ></Box>
      <Box
        sx={{
          ...dotStyle,
          animationDelay: '1s',
        }}
      ></Box>
    </Box>
  );
};
