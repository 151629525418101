import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import React, { Suspense } from 'react';
import PageLoader from '@components/page-loader';

const Layout = () => {
  return (
    <Box
      sx={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </Box>
  );
};

export default Layout;
