import { Box } from '@mui/material';
import { Loader } from '@components/loader';
import React from 'react';

const PageLoader = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flex="1 0 0">
      <Loader />
    </Box>
  );
};

export default PageLoader;
