import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import getTheme from '../../theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider, useMediaQuery } from '@mui/material';

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeModeContext = createContext<{
  mode: 'light' | 'dark';
  isSystemMode: boolean;
  setMode: (mode: 'light' | 'dark' | 'system') => void;
}>({
  mode: 'light',
  isSystemMode: true,
  setMode: () => {},
});

export const useThemeMode = () => useContext(ThemeModeContext);

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  // The default theme mode is based on the user's system preference
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState<'light' | 'dark' | 'system'>('system');

  useEffect(() => {
    const localMode = localStorage.getItem('theme-mode') as 'light' | 'dark';
    setMode(localMode || 'system');
  }, [prefersDarkMode]);

  const handleSetMode = (mode: 'light' | 'dark' | 'system') => {
    localStorage.setItem('theme-mode', mode);
    setMode(mode);
  };

  const themeMode = useMemo(() => {
    return mode === 'system' ? (prefersDarkMode ? 'dark' : 'light') : mode;
  }, [mode, prefersDarkMode]);

  return (
    <ThemeModeContext.Provider
      value={{
        mode: themeMode,
        isSystemMode: mode === 'system',
        setMode: handleSetMode,
      }}
    >
      <MuiThemeProvider theme={getTheme(themeMode)}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeModeContext.Provider>
  );
};
