import { FC, useState } from 'react';
import {
  Avatar,
  Box,
  ButtonProps,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useThemeMode } from './index';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import Icon from '@components/icon';
import CheckIcon from '@mui/icons-material/Check';
import { alpha } from '@mui/material/styles';

interface ThemeSwitcherProps {
  buttonSx?: ButtonProps['sx'];
  popoverOrigin?: PopoverOrigin;
}

export const ThemeSwitcher: FC<ThemeSwitcherProps> = ({ buttonSx, popoverOrigin }) => {
  const theme = useTheme();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { mode, isSystemMode, setMode } = useThemeMode();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'settings-popover' : undefined;

  const options: {
    label: string;
    value: 'light' | 'dark' | 'system';
    icon: JSX.Element;
  }[] = [
    { label: 'Light mode', value: 'light', icon: <LightModeIcon /> },
    { label: 'Dark mode', value: 'dark', icon: <DarkModeIcon /> },
    {
      label: `System (${prefersDarkMode ? 'dark' : 'light'})`,
      value: 'system',
      icon: <SettingsBrightnessIcon />,
    },
  ];

  return (
    <Box>
      <Avatar
        aria-describedby={id}
        sx={{
          ...buttonSx,
          cursor: 'pointer',
          width: 40,
          height: 40,
        }}
        onClick={handleClick}
        component="button"
      >
        <Icon icon="theme-mode" color={theme.palette.icon.default} />
      </Avatar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 2,
              bgcolor: alpha(theme.palette.bg.paper, 0.6),
              mt: 1,
              p: 1,
              boxShadow: 0,
              backdropFilter: 'blur(13px)',
            },
          },
        }}
        anchorOrigin={
          popoverOrigin ?? {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
      >
        <List component="nav">
          {options.map((option) => (
            <ListItem
              key={option.value}
              secondaryAction={
                (isSystemMode && option.value === 'system') ||
                (!isSystemMode && mode === option.value) ? (
                  <IconButton edge="end" sx={{ color: 'icon.default' }}>
                    <CheckIcon color="inherit" />
                  </IconButton>
                ) : (
                  <IconButton edge="end" />
                )
              }
              disablePadding
            >
              <ListItemButton onClick={() => setMode(option.value)}>
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText>{option.label}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
};
