import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import Header from '@components/header';
import Footer from '../footer';
import Wrapper from '@components/wrapper';
import RouterTabs, { Tab } from '@components/router-tabs';
import { RouterPaths } from '../../types/routers';
import PageLoader from '@components/page-loader';

const TABS: Tab[] = [
  {
    key: 'price',
    label: 'Price Feeds',
    to: RouterPaths.PriceFeeds,
  },
  {
    key: 'risk',
    label: 'Risk feeds',
    to: RouterPaths.RiskFeeds,
  },
  {
    key: 'proofs',
    label: 'PoR feeds',
    disabled: true,
  },
];

const Layout = () => {
  const { pathname } = useLocation();

  const showTabs = TABS.filter((tab) => !tab.disabled).some((tab) => pathname?.startsWith(tab.to!));
  const [stickyNavBar, setStickyNavBar] = React.useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setStickyNavBar(true);
      } else {
        setStickyNavBar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          minHeight: 'calc(100% - 120px)',
        }}
      >
        <Header />
        <Box flex="0" position="sticky" left="0" top="0" zIndex="50">
          {showTabs && (
            <Box
              sx={{
                bgcolor: stickyNavBar ? 'background.paper' : 'background.default',
              }}
            >
              <Wrapper>
                <RouterTabs data={TABS} />
              </Wrapper>
            </Box>
          )}
        </Box>
        <Box display="flex" flex="1 0 0">
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Layout;
