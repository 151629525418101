export const RouterParams = {
  FeedId: ':id',
  ClientId: ':clientId',
};

export const RouterPaths = {
  Landing: '/home',
  AuthCallback: '/auth/callback',
  PriceFeeds: '/price-feeds',
  RiskFeeds: '/risk-feeds',
  PriceFeedDetails: `/price-feeds/${RouterParams.FeedId}`,
  RiskFeedDetails: `/risk-feeds/${RouterParams.ClientId}`,
  Oracle: `/oracle`, // For sub-router, append '/*' in Route to="..."
};
