import { useAuth } from '@components/auth';
import React, { FC, ReactNode, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Popover,
  Stack,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Icon from '@components/icon';
import { Loader } from '@components/loader';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import { alpha } from '@mui/material/styles';

interface UserInfoCardProps {
  loginTooltipPlacement?: TooltipProps['placement'];
  showAvatarOnly?: boolean;
  children?: ReactNode;
  popoverOrigin?: PopoverOrigin;
}

export const UserInfoCard: FC<UserInfoCardProps> = ({
  children,
  showAvatarOnly,
  loginTooltipPlacement,
  popoverOrigin,
}) => {
  const theme = useTheme();
  const auth = useAuth();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const open = Boolean(anchorEl);
  const id = open ? 'login-popover' : undefined;

  const handleLogin = () => {
    void auth.authenticate();
  };

  if (!auth.isAuthenticated) {
    return isMobile || showAvatarOnly ? (
      <Tooltip
        title={
          <Box display="flex" gap={1} alignItems="center">
            <Icon icon="logo-google" style={{ width: 16, height: 16 }} />
            <Typography variant="body1">Login with google</Typography>
          </Box>
        }
        placement={loginTooltipPlacement}
      >
        <Avatar
          onClick={handleLogin}
          sx={{
            cursor: 'pointer',
            width: 40,
            height: 40,
          }}
        />
      </Tooltip>
    ) : (
      //
      <Button
        color="inherit"
        onClick={handleLogin}
        startIcon={
          auth.isRefreshing ? (
            <CircularProgress color="inherit" size="14px" />
          ) : (
            <Icon icon="logo-google" style={{ width: 16, height: 16 }} />
          )
        }
        sx={{
          px: 1,
        }}
      >
        {auth.isRefreshing ? <Loader size={5} p={0} /> : 'Login'}
      </Button>
    );
  }

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    void auth.logout();
    setAnchorEl(null);
  };

  if (auth.isAuthenticated && auth.user) {
    return (
      <Box>
        <Button
          aria-describedby={id}
          onClick={handleToggle}
          ref={anchorRef}
          variant="text"
          sx={{
            padding: isMobile || showAvatarOnly ? '0' : '8px',
            minWidth: 0, // override default button 40px width to allow square button
            maxWidth: '140px',
          }}
          color="inherit"
        >
          {auth.user?.picture && <Avatar src={auth.user.picture} sx={{ width: 40, height: 40 }} />}
          {!isMobile && !showAvatarOnly && (
            <Typography
              variant="link"
              sx={{ ml: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {auth.user?.name?.split(' ')[0] ?? auth.user?.email}
            </Typography>
          )}
        </Button>
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          role={undefined}
          anchorOrigin={
            popoverOrigin ?? {
              vertical: 'bottom',
              horizontal: 'center',
            }
          }
          slotProps={{
            paper: {
              sx: {
                bgcolor: alpha(theme.palette.bg.paper, 0.6),
                p: 2,
                boxShadow: 0,
                backdropFilter: 'blur(13px)',
              },
            },
          }}
          disablePortal
        >
          <Stack direction="column" spacing={1}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              {auth.user?.picture && (
                <Avatar src={auth.user.picture} sx={{ width: 48, height: 48 }} />
              )}
              {auth.user?.name && (
                <Typography
                  variant="h3"
                  sx={{
                    ml: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {auth.user?.name}
                </Typography>
              )}
              {auth.user?.email && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    ml: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {auth.user?.email}
                </Typography>
              )}
            </Box>

            {children}

            <Button color="error" onClick={handleLogout} startIcon={<ExitToAppIcon />}>
              Logout
            </Button>
          </Stack>
        </Popover>
      </Box>
    );
  }

  return null;
};
