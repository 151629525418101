import { styled } from '@mui/material';
import { SnackbarProvider as NotiStackProvider, MaterialDesignContent } from 'notistack';

type Props = {
  children: React.ReactNode;
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid',
    borderColor: theme.palette.success.main,
  },
}));

const SnackbarProvider = ({ children }: Props) => (
  <NotiStackProvider
    Components={{
      success: StyledMaterialDesignContent,
    }}
    maxSnack={10}
  >
    {children}
  </NotiStackProvider>
);

export default SnackbarProvider;
