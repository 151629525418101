import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LayoutHeaderFooter from '@components/layout-header-footer';
import LayoutFullPage from '@components/layout-full-page';
import { RouterPaths } from './types/routers';
import { AuthCallbackPage } from '@pages/auth-callback';

/**
 * Dynamic imports allow the app bundle to be code-split at the route level
 */

const LandingPage = lazy(() => import('@pages/landing'));
const PriceFeedsListPage = lazy(() => import('@pages/price-feeds-list'));
const PriceFeedsDetailsPage = lazy(() => import('@pages/price-feeds-details'));
const RiskFeedsPage = lazy(() => import('@pages/risk-feeds'));
const RiskFeedsDetailsPage = lazy(() => import('@pages/risk-feeds-details'));
const OraclePage = lazy(() => import('@pages/oracle'));

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route element={<LayoutHeaderFooter />}>
          <Route index element={<Navigate to={RouterPaths.Landing} replace />} />
          <Route path={RouterPaths.Landing} element={<LandingPage />} />
          <Route path={RouterPaths.PriceFeeds} element={<PriceFeedsListPage />} />
          <Route path={RouterPaths.PriceFeedDetails} element={<PriceFeedsDetailsPage />} />
          <Route path={RouterPaths.RiskFeeds} element={<RiskFeedsPage />} />
          <Route path={RouterPaths.RiskFeedDetails} element={<RiskFeedsDetailsPage />} />
          <Route path="*" element={<Navigate to={RouterPaths.Landing} replace />} />
        </Route>
        <Route element={<LayoutFullPage />}>
          <Route path={RouterPaths.AuthCallback} element={<AuthCallbackPage />} />
          <Route path={RouterPaths.Oracle + '/*'} element={<OraclePage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
