import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '@components/auth';
import { useEffect } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import PageLoader from '@components/page-loader';
import Icon from '@components/icon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const AuthCallbackPage = () => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    async function callback() {
      if (searchParams.has('success') && searchParams.get('success') === 'true') {
        navigate(searchParams.get('redirect') as string);
      }
    }
    void callback();
  }, [auth, searchParams, navigate]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flex="1 0 0">
      <Box>
        {!searchParams.has('error') && <PageLoader />}
        {searchParams.has('error') && (
          <Box display="flex" gap={2} flexDirection="column">
            <Box display="flex" alignItems="center" gap={2}>
              <Icon
                icon="logo-edge"
                style={{ width: 32, height: 32 }}
                color={theme.palette.error.main}
              />
              <Typography variant="h2">Authentication failed</Typography>
            </Box>
            <Typography variant="body1">
              An error occurred while authenticating with Google, please try again later.
            </Typography>
            {searchParams.get('error') && (
              <Typography variant="subtitle2" color="error">
                Error: {searchParams.get('error')}
              </Typography>
            )}
            {searchParams.get('redirect') && (
              <Button
                color="primary"
                size="small"
                href={searchParams.get('redirect') as string}
                startIcon={<ArrowBackIcon />}
              >
                Go back
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
