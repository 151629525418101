import { NavLink } from 'react-router-dom';
import { Box, Typography, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

export interface Tab {
  key: string;
  label: string | ReactNode;
  to?: string;
  disabled?: boolean;
}

interface RouterTabsProps {
  data: Tab[];
}

const StyledLink = styled(NavLink)(({ theme }) => {
  const color =
    theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.dark;
  return {
    display: 'block',
    textDecoration: 'none',
    padding: '16px 8px',
    position: 'relative',
    overflow: 'visible',
    color: color,

    ['&:before']: {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    ['&:after']: {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      width: 16,
      height: 2,
      backgroundColor: color,
      transform: 'translateX(-50%)',
      transition: 'width .3s ease, backgorund-color .3s ease',
    },

    ['&:hover']: {
      ['&:after']: {
        width: '100%',
      },
    },

    ['&.active']: {
      ['&:before']: {
        background: `linear-gradient(180deg, ${alpha(color, 0)} 0%, ${alpha(color, 0.16)} 100%)`,
        filter: 'blur(4px)',
      },
      ['&:after']: {
        width: '100%',
        backgroundColor: color,
      },
    },
  };
});

const RouterTabs = ({ data }: RouterTabsProps): JSX.Element => {
  return (
    <Box display="flex" columnGap={3} px={3} borderBottom="1px solid" borderColor="bg.paperHover">
      {data.map(({ key, label, to, disabled }: Tab) =>
        disabled || !to ? null : (
          <StyledLink to={to} key={key}>
            <Typography variant="label" color="textPrimary">
              {label}
            </Typography>
          </StyledLink>
        )
      )}
    </Box>
  );
};

export default RouterTabs;
