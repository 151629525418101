import axios from 'axios';

export const API_WITH_CREDENTIALS = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export default axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
